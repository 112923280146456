<template>
  <transition name="fade" appear appear-active-class="fade-enter-active">
    <div>
      <div class="additional">
        <template v-if="getContractType() === 'CONTRACT_WITH_CUSTOMER'">
          <h2>Субподрядные договоры</h2>
          <p v-if="!contract.subContracts || contract.subContracts.length === 0">Нет субподрядных договоров</p>
          <div class="grid30" v-else>
            <div v-for="(subContract, subContractIndex) in contract.subContracts"
                 :key="'subcontr_'+subContractIndex">
              <a :href="'/contract/'+subContract.id" target="_blank">Договор № {{ subContract.contractNumber }} от
                {{ subContract.startDate | formatDate }}</a>

              <h4>тема</h4>
              <p>{{ subContract.theme }}</p>

              <h4>сумма договора</h4>
              <p class="summ">{{ subContract.contractSum | formatPrice }}</p>

              <h4>дата начала и окончания</h4>
              <p>{{ subContract.startDate | formatDate }} – {{ subContract.endDate | formatDate }}</p>

              <div class="badge">{{ contractStatusDict[subContract.contractStatus] }}</div>
            </div>
          </div>
        </template>

        <template v-if="getContractType() === 'CONTRACT_WITH_SUPPLIER'">
          <h2>Связанный договор с заказчиком</h2>
          <p v-if="!contract.parentContract">Нет сязанного договора с заказчиком</p>
          <div class="grid30" v-else>
            <div>
              <a :href="'/contract/'+contract.parentContract.id" target="_blank">Договор №
                {{ contract.parentContract.contractNumber }} от {{ contract.parentContract.startDate | formatDate }}</a>

              <h4>тема</h4>
              <p>{{ contract.parentContract.theme }}</p>

              <h4>сумма договора</h4>
              <p class="summ">{{ contract.parentContract.contractSum | formatPrice }}</p>

              <h4>дата начала и окончания</h4>
              <p>{{ contract.parentContract.startDate | formatDate }} – {{
                  contract.parentContract.endDate | formatDate
                }}</p>

              <div class="badge">{{ contractStatusDict[contract.parentContract.contractStatus] }}</div>
            </div>
          </div>
        </template>

        <h2>Дополнительные соглашения</h2>
        <p v-if="!contract.agreements || contract.agreements.length === 0">Нет дополнительных соглашений</p>
        <div class="grid30" v-else>
          <div v-for="(agreement, agreementIndex) in contract.agreements"
               :key="'agreement_'+agreementIndex">
            № {{ agreement.number }} от {{ agreement.date | formatDate }}

            <h4>тема</h4>
            <p>{{ agreement.description }}</p>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import {dictToObject} from "@/modules/CommonUtils";
import {contractStatusDict} from "@/modules/NSI";

export default {
  name: "AdditionalTab",
  props: ['contract'],
  data() {
    return {
      contractStatusDict: dictToObject(contractStatusDict),
    }
  },
  methods: {
    getContractType() {
      if (!this.contract.customer || !this.contract.supplier) return undefined
      if (!this.contract.customer.isOurOrg && this.contract.supplier.isOurOrg) return 'CONTRACT_WITH_CUSTOMER'
      else if (this.contract.customer.isOurOrg && !this.contract.supplier.isOurOrg) return 'CONTRACT_WITH_SUPPLIER'
      else if (!this.contract.customer.isOurOrg && !this.contract.supplier.isOurOrg) return 'EXTERNAL_CONTRACT'
      else return 'INTERNAL_CONTRACT'
    }

  }
}
</script>

<style scoped>

</style>